import * as React from 'react';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { CollapsibleSection, MediaPicker, Title } from 'common/components';
import {
  useFunfactDetails,
  useFunfactsMediaAdd,
  useFunfactsMediaDelete,
} from '../queries';

const fieldName = 'media_file_pk';

type Props = {
  pk?: PK;
};

const FunfactMediaPicker = ({ pk: funfactPk }: Props) => {
  const details = useFunfactDetails(funfactPk);
  const add = useFunfactsMediaAdd();
  const remove = useFunfactsMediaDelete();
  const saveStatus = {
    isSuccess: add.isSuccess || remove.isSuccess,
    isLoading: add.isLoading || remove.isLoading,
    isError: add.isError || remove.isError,
  };
  const defaultValues = details.data && [details.data.media_file];

  const handleSave = (mediaPks: PK[]) => {
    if (funfactPk) {
      if (mediaPks.length > 0) {
        add.mutate({ pk: funfactPk, [fieldName]: mediaPks[0] });
      } else {
        remove.mutate({ pk: funfactPk });
      }
    }
  };

  return (
    <>
      <CollapsibleSection
        title="Add media"
        saveStatus={saveStatus}
        divider={false}
        disabled={!details.data}
      >
        <Title size="standard" marginBottom={1.5}>
          Linked image
        </Title>
        <MediaPicker
          onSave={handleSave}
          name={fieldName}
          mediaType={MEDIA_TYPES.IMAGE}
          defaultValues={defaultValues}
        />
      </CollapsibleSection>
    </>
  );
};

export default FunfactMediaPicker;
