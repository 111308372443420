import { Flatten } from './utils';

const apiUrls = {
  ADMINS: {
    LIST: '/cms/users/admins',
    SET: '/cms/users/set_admin',
    UNSET: '/cms/users/unset_admin',
  },
  CONFIGURATION: {
    BASE: '/cms/configuration',
  },
  CACHE: {
    CLEAR: '/cms/cache/clear',
  },
  AUTH: {
    LOGIN: '/cms/auth/login',
    LOGIN_TOKEN: '/cms/auth/login_token',
    LOGOUT: '/auth/logout',
    WHOAMI: '/user/whoami',
    SSO_LOGIN: '/auth/sso_login',
    SSO_SESSION: '/auth/sso_session',
  },
  USERS: {
    DELETE: '/cms/users/delete_account',
  },
  MEDIA: {
    LIBRARY: '/cms/media_library',
    DETAILS: '/cms/media_library/:pk',
    FILES: '/cms/media_files',
    FILES_CONTENT: '/cms/media_files/:pk/content',
  },
  CONTENT: {
    LIST: '/cms/content',
  },
  BLOGS: {
    LIST: '/cms/blogs',
    DETAILS: '/cms/blogs/:pk',
    METADATA: '/cms/blogs/preview',
  },
  MINIS: {
    LIST: '/cms/minis',
    DETAILS: '/cms/mini/:pk',
    TAGS: '/cms/mini/:pk/tags',
    TAGS_LIST: '/cms/minis/tags',
    MEDIA: '/cms/mini/:pk/media',
    FACTS: '/cms/mini/:pk/facts',
    RECIPES: '/cms/mini/:pk/recipes',
    WORKOUTS: '/cms/mini/:pk/workout',
    MEDITATIONS: '/cms/mini/:pk/meditation',
    PODCASTS: '/cms/mini/:pk/podcast',
  },
  SERIES: {
    LIST: '/cms/series',
    DETAILS: '/cms/series/:pk',
    TAGS: '/cms/series/:pk/tags',
    DAY: '/cms/series/:pk/days/:day',
    DAYS: '/cms/series/:pk/days',
    BACKUP: '/cms/series/:pk/backup_minis',
    MEDIA: '/cms/series/:pk/media',
    FOLLOWUP: '/cms/series/:pk/follow_up',
    DUPLICATE: '/cms/series/:pk/copy',
  },
  COMPANIES: {
    LIST: '/cms/companies',
    DETAILS: '/cms/companies/:pk',
    LOGO: '/cms/companies/:pk/logo',
    PROGRAM: '/cms/companies/:pk/program',
    ACTIVATE: '/cms/companies/:pk/activate',
    DEACTIVATE: '/cms/companies/:pk/deactivate',
    EMAILS: '/cms/companies/:pk/billing_emails',
    ADMINS: {
      LIST: '/cms/companies/:pk/admins',
      DETAILS: '/cms/companies/:companyPk/admins/:adminPk',
    },
    INVITATIONS: {
      LIST: '/cms/companies/:pk/promotion_requests',
      DETAILS: '/cms/companies/:companyPk/promotion_requests/:invitationPk',
      RESEND: '/cms/companies/:companyPk/promotion_requests/:invitationPk/resend',
    },
  },
  COMPETITIONS: {
    START_DATES: '/cms/competitions/start_dates',
  },
  CONTENT_GOALS: {
    LIST: '/cms/content_goals',
  },
  THEMES: {
    LIST: '/cms/themes',
    DETAILS: '/cms/themes/:pk',
  },
  COLLECTIONS: {
    LIST: '/cms/discovery/collections',
    ORDER: '/cms/discovery/collections/order',
    DETAILS: '/cms/discovery/collections/:pk',
    CONTENT: '/cms/discovery/collections/:pk/content',
    CONTENT_DETAILS: '/cms/discovery/collections/:collectionPk/content/:contentPk',
    HANDLERS: '/cms/discovery/collections/handlers',
    ITEMS: {
      LIST: '/cms/discovery/collections/:collectionPk/content/:contentPk/items',
      DETAILS:
        '/cms/discovery/collections/:collectionPk/content/:contentPk/items/:itemPk',
      ORDER: '/cms/discovery/collections/:collectionPk/content/:contentPk/items/order',
    },
  },
  FUNFACTS: {
    LIST: '/cms/funfacts',
    DETAILS: '/cms/funfact/:pk',
    MEDIA: '/cms/funfact/:pk/media',
    SCHEDULE: '/cms/funfact/:pk/schedule',
  },
  MEDITATIONS: {
    LIST: '/cms/meditation',
    DETAILS: '/cms/meditation/:pk',
    TAGS: '/cms/meditation/:pk/tags',
  },
  PODCASTS: {
    LIST: '/cms/podcast',
    DETAILS: '/cms/podcast/:pk',
    TAGS: '/cms/podcast/:pk/tags',
  },
  WORKOUTS: {
    LIST: '/cms/workout',
    DETAILS: '/cms/workout/:pk',
    TAGS: '/cms/workout/:pk/tags',
  },
  HABITS: {
    LIST: '/cms/habits',
    DETAILS: '/cms/habits/:pk',
    LEVEL: '/cms/habits/:pk/levels/:lvl',
    MINIS: '/cms/habits/:pk/levels/:lvl/minis',
  },
  FACTS: {
    LIST: '/cms/facts',
    DETAILS: '/cms/fact/:pk',
  },
  BADGES: {
    LIST: '/cms/badges',
    DETAILS: '/cms/badges/:pk',
    MODIFY: '/cms/badges',
    COLLECTIONS: {
      LIST: '/cms/badges/collections',
    },
  },
  RECIPES: {
    LIST: '/cms/recipes',
    DETAILS: '/cms/recipes/:pk',
    TAGS: '/cms/recipes/:pk/tags',
    CATEGORIES: '/cms/recipes/:pk/categories',
    INGREDIENTS: '/cms/recipes/:pk/ingredients',
    NUTRITIONAL_VALUES: '/cms/recipes/:pk/nutritional_values',
    PREPARATIION: '/cms/recipes/:pk/preparation',
    MEDIA: '/cms/recipes/:pk/media',
  },
  AUTHORS: {
    LIST: '/cms/recipes/authors',
    DETAILS: '/cms/recipes/authors/:pk',
  },
  CATEGORIES: {
    LIST: '/cms/recipes/categories',
    DETAILS: '/cms/recipes/categories/:pk',
  },
  INGREDIENTS: {
    LIST: '/cms/recipes/ingredients',
    DETAILS: '/cms/recipes/ingredients/:pk',
  },
  NUTRITIONAL_VALUES: {
    LIST: '/cms/recipes/nutritional_values',
    DETAILS: '/cms/recipes/nutritional_values/:pk',
  },
  TAGS: {
    LIST: '/cms/recipes/tags',
    DETAILS: '/cms/recipes/tags/:pk',
  },
  UNITS: {
    LIST: '/cms/recipes/units',
    DETAILS: '/cms/recipes/units/:pk',
  },
  SCENARIOS: {
    LIST: '/cms/scenarios',
    DETAILS: '/cms/scenarios/:pk',
    DUPLICATE: '/cms/scenarios/:pk/copy',
    WEEKS: {
      LIST: '/cms/scenarios/:pk/weeks',
      DETAILS: '/cms/scenarios/:pk/weeks/:weekPk',
      ORDER: '/cms/scenarios/:pk/weeks/order',
    },
    MINIS: {
      LIST: '/cms/scenarios/:pk/weeks/:weekPk/days/:dayNo/minis',
      DETAILS: '/cms/scenarios/:pk/weeks/:weekPk/days/:dayNo/minis/:miniPk',
      ORDER: '/cms/scenarios/:pk/weeks/:weekPk/days/:dayNo/minis/order',
    },
  },
  TASKS: {
    LIST: '/cms/background_tasks/failed',
    RESTART: '/cms/background_tasks/:pk/reset',
  },
  TAGGING: {
    SCHEMA: '/cms/tags_metadata/:contentType',
  },
} as const;

export type ApiUrl = Flatten<typeof apiUrls>;

export default apiUrls;
